import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  d: "M0.771429 6.18143V-2.48429H20.1086V6.18143H0.771429ZM2.54571 4.25286H18.36V-0.555714H2.54571V4.25286ZM5.11714 1.14143H16.1743V2.55571H5.11714V1.14143ZM0.81 16.8786V8.21286H20.1471V16.8786H0.81ZM2.58429 14.9114H18.4114V10.0643H2.58429V14.9114ZM4.97571 11.8386H16.0329V13.2529H4.97571V11.8386Z",
  transform: "translate(-0.617035 3)",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}